import React from 'react'

function Message(props){
    return (
        <div className="container message d-flex flex-column align-items-center justify-content-center">
            <div className="message-head"> <h1>{props.redirect? "Redirecting..." : props.success ? "Success!" : "Error!"}</h1></div>
            <div className="message-body"><p id="message"></p></div>
        </div>
    )
}

export default Message;
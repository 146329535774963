import React, {useEffect, useState} from 'react';
import logo from "./assets/images/zarty.png"
import Message from './components/Message';
import {setPasswordDataService, updatePasswordService, addUserService} from "./service/resetPassword"
function App() {
  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [documentData, setDocumentData] = useState("");
  const [loadingResults, setLoadingResults] = useState(false);
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState(true);
  const [finish, setFinish] = useState(false);
  const [waitlist, setWaitlist] = useState(false);
  
  useEffect(()=>{
    const checkDevice = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const link = urlParams.get('link');
        const check = urlParams.get('check');
        const documentID = urlParams.get('docId');
        console.log(link, 'link here');
        if (documentID==='waitlist') {
            if (/iphone|ipod|ipad|android|blackberry|opera mini|opera mobi|skyfire|maemo|windows phone|palm|iemobile|symbian|symbianos|fennec/i.test(navigator.userAgent.toLowerCase())) { 
                if (check) {
                    setWaitlist(true);
                    setRedirect(false);
                    setMessage('Please make sure that Zarty is installed in your device');
                } else if(link){
                    window.location = link;
                } 
            } else {
                setWaitlist(true);
                setRedirect(false);
                setMessage('Please open the link in your iOS or android device with Zarty installed in it');
            }
            return;
        }
        const resp = await setPasswordDataService(documentID);
        if(resp.success && resp.data){
            if(!checkIfLinkExpired(resp.data.date.seconds, 72)){
                setDocumentData(resp.data);
                
                if (/iphone|ipod|ipad|android|blackberry|opera mini|opera mobi|skyfire|maemo|windows phone|palm|iemobile|symbian|symbianos|fennec/i.test(navigator.userAgent.toLowerCase())) { 
                    if(check) setLoading(false); 
                    else if(link) window.location = link;
                }
                else{
                    setLoading(false)
                }
            }else{
                setMessage("Link expired. Please generate a new link and try to set password within 72 hours.");
                setSuccess(false);
            }
        } else {
            setMessage("Your link is not valid please raise another request for reset password through zarty app");
            setSuccess(false);
        }
    }
    checkDevice();
  },[])

  const checkIfLinkExpired = (date, hours) => {
    let currentTime = new Date();
    currentTime = currentTime.getTime();
    const seconds = date;
    if (seconds === false) {
        return true;
    } else {
        const miliSeconds = seconds * 1000;
        const expireTime = miliSeconds + hours * 60 * 60 * 1000;
        if (expireTime > currentTime) {
        return false;
        }
        return true;
    }
  };
  const setMessage = (message) => {
      document.getElementById("message").innerHTML = message;
      setRedirect(false);
  }
  const updatePassword = async (e) => {
    e.preventDefault();
    let pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%* #=+\(\)\^?&])[A-Za-z\d$@$!%* #=+\(\)\^?&]{6,}$/;
    if(pattern.test(newPassword)){
        if(newPassword === confirmPassword){
            setLoadingResults(true);
            const resp = documentData.linkType === "resetPassword" ? await updatePasswordService(documentData.email, confirmPassword, false) : await addUserService(documentData.email, confirmPassword, documentData.username);
            setFinish(true);
            if(resp.success){
                setSuccess(true);
                setMessage("Your password has been successfully updated.")
            }
            else{
                setSuccess(false);
                if (resp.errorMessage) {
                    setMessage(resp.errorMessage);
                } else {
                    setMessage("There is some error while updating the password. Please try again in some time");
                }
            }
            setDocumentData("");
            setLoadingResults(false);
        }
        else{
            document.getElementById("newPass").style.borderColor = "lightgray";
            document.getElementById("confirmPass").style.borderColor = "red";
        }
    }else{
        document.getElementById("newPass").style.borderColor = "red";
        document.getElementById("confirmPass").style.borderColor = "red";
    }
  }

  if (waitlist) {
    return (<Message redirect={false} success={false} />)
  }

  return (
    !loading && !finish ? (
    <div className="container-fluid p-4">
            <div className="row d-flex justify-content-center logo">
                <img src={logo} alt="Zarty" className="img-fluid logo-image"/>
            </div>
            <form>
                <div className="form-header">
    <h2 className="row d-flex justify-content-center">{documentData.linkType==="resetPassword" ? "Reset" : "Set"} password</h2>
                    <p className="row d-flex justify-content-center">Please choose a new password and confirm it below.</p>
                </div>
                <div className="form-body mt-5">
                    <div className="row d-flex justify-content-center">
                        <div className="form-group password">
                            <input type="password" className="form-control" id="newPass" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                            <small className="form-text password-requirement">Passwords must be 6 characters long,
                                with 1 number and one special character</small>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="form-group password">
                            <input type="password" className="form-control" id="confirmPass" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-4">
                    <button className="btn btn-warning submit-btn" onClick={e => updatePassword(e)}>{loadingResults ? (<div class="spinner-border spinner-border-sm text-primary" role="status"></div>): "Submit"}</button>
                    </div>
                    
                </div>
            </form>
            <footer className="d-flex flex-column align-items-center">
                <div className="text-muted">Zarty</div>
                <small className="text-muted">500 W 25th St., New York, NY 10002</small>
                <div><a href="https://www.zarty.com/zarty-terms-of-use" className="text-primary" target="_blank">Terms of Service</a> <span className="text-muted">.</span> <a href="https://www.zarty.com/privacy" className="text-primary" target="_blank">Privacy Policy</a></div>
            </footer>
        </div>
    ) : <Message redirect={redirect} success={success} />
  );
}
export default App;

import {db, functions} from '../config/firebase';

// getting data from password reset collection to check whether the password
// reset request has been made or not
export const setPasswordDataService = async (documentId) => {
  try {
  
    const querySnapShot = await db
      .collection('passwordReset')
      .doc(documentId)
      .get();
    const data = querySnapShot.data();
    return {
      success: true,
      error: false,
      data,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
      data: error,
    };
  }
};

// update the password either by user settings (authenticated is true)
// or by forgot password - reset password flow (authenticated is false)
export const updatePasswordService = async (email, password, authenticated) => {
  try {
    const result = await functions.httpsCallable('updatePassword')({
      email,
      password,
      authenticated,
    });

    return {
      success: true,
      data: result,
      error: false,
    };
  } catch (error) {
    return {
      success: false,
      data: error,
      error,
    };
  }
};

// creating a user in DB, credentials are created by admin this function
// adds the user to DB confirming the password
export const addUserService = async (email, password, username) => {
  try {
    const result = await functions.httpsCallable('createUser')({
      email,
      password,
      username,
    });

    const resultData = result.data ? result.data.success : false

    return {
      success: resultData,
      data: result,
      error: false,
      errorMessage: resultData ? false: "Max user limit has reached, please make sure you're in the waitlist to know about the opening first",
    };
  } catch (error) {
    return {
      success: false,
      data: error,
      error,
    };
  }
};
import * as firebase from 'firebase';

// prod
const config = {
  apiKey: 'AIzaSyBXi13_BTCO7E2NJhmUgCpXA7t0UBjSEl4',
  authDomain: 'zarty-c3b13.firebaseapp.com',
  databaseURL: 'https://zarty-c3b13.firebaseio.com',
  projectId: 'zarty-c3b13',
  storageBucket: 'zarty-c3b13.appspot.com',
  messagingSenderId: '749310170607',
  appId: '1:749310170607:web:15aa11bb8328c2e3fbd21a',
  measurementId: 'G-6VXX46ZKVY',
};

// dev
// const config = {
//   apiKey: 'AIzaSyAH4jON6Upq7eb1IuRaDg2733efot72e2Q',
//   authDomain: 'sandbox-36053.firebaseapp.com',
//   projectId: 'sandbox-36053',
//   storageBucket: 'sandbox-36053.appspot.com',
//   messagingSenderId: '828404787943',
//   appId: '1:828404787943:web:558d5785b7260b8e43d042',
//   measurementId: 'G-7YVJLQPXXL',
// };

firebase.initializeApp(config);
export const db = firebase.firestore();
export const functions = firebase.functions();
export default firebase;